import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { DrawerHeader } from '../helpers/general';
import CustomInput from './shared/CustomInput';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ReactComponent as Checklist } from '../assets/icons/checklist.svg';
import { ReactComponent as Trash } from '../assets/icons/trash.svg';
import Carousel from 'react-material-ui-carousel';
import {
  deleteTable,
  getDetailTable,
  getTable,
  updateTable,
  uploadImage,
  uploadVideo,
} from '../api/table';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@emotion/react';
import CustomButton from './shared/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import CustomDialog from './shared/CustomDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeIdexGrid,
  getColumn,
  getIndexGrid,
  getRow,
} from '../store/area/detailArea';
import { getDetailArea } from '../api/Area';
import CustomCard from './shared/CustomCard';

const TableEditForms = ({ matches, permissions }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { idArea } = useParams();
  const navigate = useNavigate();
  const [clicked, setCliked] = useState(true);
  const [cellChoose, setCellChoose] = useState(null);
  const [column, setColumn] = useState();
  const [row, setRow] = useState();
  const [dataListTable, setDataListTable] = useState();

  const { editTableId } = useParams();
  const [outletDimensionRoomImageId, setOutletDimensionRoomImageId] =
    useState(null);

  // ! position list
  const [position, setPosition] = useState({});
  const [xPosition, setXPosition] = useState(0);
  const [yPosition, setYPosition] = useState(0);

  //! name
  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState(false);

  //! capacity
  const [capacity, setCapacity] = useState('');
  const [errorCapacity, setErrorCapacity] = useState(false);

  // ! get spesifik area
  const fetchSpesifikArea = async () => {
    setIsLoading(true);
    try {
      const data = await getDetailArea(idArea);
      setColumn(data.data?.column);
      setRow(data.data?.row);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchSpesifikArea();
  }, []);

  useEffect(() => {
    const fetchListTable = async () => {
      let params = {};
      params.pageSize = 100;
      params.outletDimensionRoomId = idArea;
      params.indexMode = 0;
      try {
        const {
          data: { outletTables },
        } = await getTable(params);
        setDataListTable(outletTables);
      } catch (err) {
        console.error();
      }
    };
    fetchListTable();
  }, [idArea]);

  // ! fetch detail table
  useEffect(() => {
    const fetchDetailTable = async () => {
      try {
        const { data } = await getDetailTable(editTableId);
        setDataListTable(data);
        setXPosition();
        setYPosition();
        setName(data?.name);
        setCapacity(data?.capacity);
      } catch (err) {
        console.error();
      }
    };
    fetchDetailTable();
  }, [editTableId]);

  // ! ---------------- get width and height element ----
  const myRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const test = () => {
    if (myRef.current) {
      setWidth(myRef.current.clientWidth);
      setHeight(myRef.current.clientHeight);
    }
  };

  // useLayoutEffect(() => {
  //   function updateWidth() {
  //     if (myRef.current) {
  //       setWidth(myRef.current.clientWidth);
  //       setHeight(myRef.current.clientHeight);
  //     }
  //   }
  //   window.addEventListener('click', updateWidth);
  //   updateWidth();
  //   return () => {
  //     window.removeEventListener('click', updateWidth);
  //   };
  // }, [myRef]);

  // ! ---------------- get coordinat ------------------
  const handleMouseClick = (event) => {
    const localX = event.clientX - event.target.offsetLeft;
    const localY = event.clientY - event.target.offsetTop;

    setPosition({ x: localX, y: localY });
  };

  useEffect(() => {
    setXPosition(
      matches
        ? ((position.x - 40) / width) * 100
        : ((position.x - 400) / width) * 100
    );
    setYPosition(((position.y - 135) / height) * 100);
  }, [position, width, height]);

  //! --------------------- handle --------------------------

  const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (capacity !== '') {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [capacity]);

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = () => {
    let datas = {};
    datas.name = name;
    datas.capacity = capacity;
    datas.outlet_dimension_room_image_id = outletDimensionRoomImageId;

    if (xPosition > 0) {
      datas.x_position = xPosition;
    }
    if (yPosition > 0) {
      datas.y_position = yPosition;
    }

    const submitUpdateTable = async () => {
      setIsLoading(true);
      try {
        const data = await updateTable(editTableId, datas);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    submitUpdateTable();
    navigate(-1);
  };

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleDelete = () => {
    const deleted = async () => {
      setIsLoading(true);
      try {
        await deleteTable(editTableId);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    deleted();
    navigate(-1);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 2,
        p: 2,
      }}
    >
      <Box>
        <DrawerHeader />
        <Grid container columns={12} spacing={2}>
          {/* image table */}
          <Grid item xs={12}>
            <CustomCard>
              <Box>
                <Typography sx={{ fontSize: '14px' }}>
                  Click the table to select position
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, m: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box
                      sx={{
                        width: '10px',
                        height: '10px',
                        background: '#4FD240',
                        borderRadius: '3px',
                      }}
                    ></Box>
                    <Typography sx={{ fontSize: '8px' }}> Selected</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box
                      sx={{
                        width: '10px',
                        height: '10px',
                        background: '#F33A3A',
                        borderRadius: '3px',
                      }}
                    ></Box>
                    <Typography sx={{ fontSize: '8px' }}> Existing</Typography>
                  </Box>
                </Box>
              </Box>

              <Carousel
                index={dataListTable?.dimensionRoom?.images?.length - 1}
                autoPlay={true}
                navButtonsAlwaysInvisible={true}
                changeOnFirstRender={true}
                onChange={() => {
                  clicked && test();
                }}
                interval={3600000}
              >
                {dataListTable?.dimensionRoom?.images.map((item, index) => (
                  <Box
                    key={index}
                    ref={myRef}
                    sx={{
                      width: '100%',
                      // height: '100%',
                      position: 'relative',
                      borderRadius: '12px',
                      backgroundImage: `url(${item.imageUrl})`,
                      aspectRatio: '2/1',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                    onChange={() => {
                      setCliked(false);
                    }}
                    onClick={(event) => {
                      setOutletDimensionRoomImageId(item?.id);
                      handleMouseClick(event);
                      // setPreview(true);
                      setCliked(false);
                    }}
                  >
                    {item?.outletTable.map((detail, idx) => (
                      <Box
                        sx={{
                          background:
                            dataListTable?.id === detail?.id
                              ? '#4FD240'
                              : '#F33A3A',
                          padding: '0px 4px',
                          minWidth: '41px',
                          height: '16px',
                          borderRadius: '4px',
                          // border: '1px solid #000',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'absolute',
                          left:
                            dataListTable?.id === detail?.id && xPosition >= 0
                              ? `${xPosition}%`
                              : `${detail?.xPosition}%`,
                          top:
                            dataListTable?.id === detail?.id && yPosition >= 0
                              ? `${yPosition}%`
                              : `${detail?.yPosition}%`,
                        }}
                        onClick={(event) => event.stopPropagation()}
                      >
                        <Typography
                          sx={{
                            fontSize: '8px',
                            fontWeight: 600,
                            color: '#FFFFFF',
                          }}
                        >
                          {dataListTable?.id === detail?.id
                            ? name
                            : detail?.name}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Carousel>
            </CustomCard>
          </Grid>

          {/* name */}
          <Grid item xs={6} sx={{ mt: 2 }}>
            <CustomInput
              // disabled
              label="Table Name*"
              readOnly={!permissions.includes('edit-outlet-table')}
              fullWidth
              value={name}
              onChange={(event) => {
                setName(event.target.value);
                setErrorName(false);
              }}
              sx={{
                '& .MuiOutlinedInput-input': {
                  paddingInline: '14px',
                  fontSize: '0.875rem',
                },
              }}
              error={errorName}
              helperText={errorName && 'required'}
            />
          </Grid>

          {/* capacity */}
          <Grid item xs={6} sx={{ mt: 2 }}>
            <CustomInput
              label="Capacity*"
              readOnly={!permissions.includes('edit-outlet-table')}
              fullWidth
              value={capacity}
              onChange={(event) => {
                setCapacity(event.target.value);
                setErrorCapacity(false);
              }}
              sx={{
                '& .MuiOutlinedInput-input': {
                  paddingInline: '14px',
                  fontSize: '0.875rem',
                },
              }}
              error={errorCapacity}
              helperText={errorCapacity && 'required'}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        {/* button delete */}
        <CustomButton
          onClick={() => setIsDeleteDialogOpen(true)}
          type="submit"
          fullWidth
          variant="contained"
          color="error"
        >
          <Typography sx={{ color: '#FFFFFF' }}>Delete</Typography>
        </CustomButton>

        {/* button submit */}
        <CustomButton
          onClick={() => setIsSubmitDialogOpen(true)}
          disabled={!isDisabled}
          type="submit"
          fullWidth
          variant="contained"
        >
          Save
        </CustomButton>
      </Box>
      {/* dialog submit */}
      <CustomDialog
        isOpen={isSubmitDialogOpen}
        setIsOpen={setIsSubmitDialogOpen}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              padding: '30px 0px 0px 35px',
            }}
          >
            <Box
              sx={{
                width: '43px',
                height: '43px',
                background: 'rgba(81, 177, 92, 0.1)',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Checklist />
            </Box>
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              Edit Mapping
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ padding: '10px 0px 50px 35px' }}>
            <Typography sx={{ fontSize: '14px' }}>
              Are you sure you want to save changes?
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              padding: '30px 55px 0px 35px',
            }}
          >
            <Box
              onClick={() => setIsSubmitDialogOpen(false)}
              sx={{ width: '100px', cursor: 'pointer' }}
            >
              <Typography
                sx={{ fontSize: '14px', color: '#9E9D9D', fontWeight: 600 }}
              >
                Back
              </Typography>
            </Box>

            <CustomButton
              onClick={handleSubmit}
              disabled={isLoading}
              type="submit"
              variant="contained"
              sx={{ width: '100px' }}
            >
              Save
            </CustomButton>
          </Box>
        </DialogContent>
      </CustomDialog>

      {/* custom Detele */}
      <CustomDialog
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              padding: '30px 0px 0px 35px',
            }}
          >
            <Box
              sx={{
                width: '43px',
                height: '43px',
                background: ' rgba(243, 58, 58, 0.1)',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Trash />
            </Box>
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              Delete Table
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ padding: '10px 0px 50px 35px' }}>
            <Typography sx={{ fontSize: '14px' }}>
              Are you sure you want to delete changes?
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              padding: '30px 55px 0px 35px',
            }}
          >
            <Box
              onClick={() => setIsDeleteDialogOpen(false)}
              sx={{ width: '100px', cursor: 'pointer' }}
            >
              <Typography
                sx={{ fontSize: '14px', color: '#9E9D9D', fontWeight: 600 }}
              >
                Back
              </Typography>
            </Box>

            <CustomButton
              onClick={handleDelete}
              disabled={isLoading}
              type="submit"
              variant="contained"
              color="error"
              sx={{ width: '100px' }}
            >
              <Typography sx={{ color: '#FFFFFF' }}>Delete</Typography>
            </CustomButton>
          </Box>
        </DialogContent>
      </CustomDialog>
    </Box>
  );
};
export default TableEditForms;
